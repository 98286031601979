import React from 'react'
import Logo from './Logo'
import { AppBar, Container } from '@mui/material'

const Header: React.FC = () => {
    return (
        <AppBar
            position="relative"
            sx={{
                boxShadow: 'none',
                borderBottomWidth: 1,
                borderBottomStyle: 'solid',
                borderBottomColor: 'divider',
                bgcolor: 'background.default'
            }}
        >
            <Container sx={{ py: 1 }}>
                <Logo
                    sx={{
                        color: 'primary.main',
                        height: {
                            xs: 30,
                            sm: 40,
                            md: 50
                        },
                        width: {
                            xs: 30,
                            sm: 40,
                            md: 50
                        }
                    }}
                />
            </Container>
        </AppBar>
    )
}

export default Header
