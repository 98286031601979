import React from "react";
import { Box, SxProps, Typography } from "@mui/material";

interface Props {
  sx?: SxProps;
}

const Objective: React.FC<Props> = ({ sx }) => {
  return (
    <Box sx={sx}>
      <Typography variant="body2" sx={{ fontWeight: "500", mb: 2 }}>
        Stipendierna är avsedda för konkret hjälp på vägen i utvecklingen som
        redan yrkesverksam ung sångare, dansare eller musiker i nämnda genrer.
        Det kan exempelvis handla om nischade utbildningar/kurser,
        specialpedagogik, etc. Stipendierna är inte avsedda vara allmänt
        studiestöd, meritstipendier, arbetslöshetsersättning eller för
        instrumentinköp. Primärt är inte heller finansiering av musikutgivning
        (CD, vinyl, streaming), men detta kan ändå vägas in vid bedömning.
      </Typography>
    </Box>
  );
};

export default Objective;
