import React from 'react'
import { Box, SxProps } from '@mui/material'

interface Props {
    sx?: SxProps
}

const Logo: React.FC<Props> = ({ sx }) => {
    return (
        <Box sx={sx}>
            <svg
                width="100%"
                height="100%"
                viewBox="0 0 3467 2530"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M250 2280V308.387C250 289.333 274.117 281.072 285.799 296.124L1069.85 1306.29C1078.41 1317.32 1095.36 1316.41 1102.68 1304.51L1746.15 259.513C1749.79 253.601 1756.24 250 1763.18 250H2484.05M3216.08 250H2484.05M2484.05 250V2280"
                    stroke="currentColor"
                    strokeWidth="500"
                    strokeLinecap="round"
                    strokeLinejoin="bevel"
                />
            </svg>
        </Box>
    )
}

export default Logo
