import React from "react";
import {
  FeedOutlined,
  Instagram,
  Language,
  MusicVideo,
} from "@mui/icons-material";
import { Box, Divider, Link, SxProps, Typography } from "@mui/material";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
  timelineItemClasses,
} from "@mui/lab";
import { fellows } from "src/data/fellows";
import { Fellow } from "src/types";

interface Props {
  sx?: SxProps;
}

interface CardProps {
  fellow: Fellow;
}

const Card: React.FC<CardProps> = ({ fellow }) => {
  return (
    <Box sx={{ mt: 1.5 }}>
      <Box>
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{ lineHeight: 1, fontWeight: "500" }}
        >
          {fellow.category}
        </Typography>
        <Typography variant="body1" sx={{ fontWeight: "700" }}>
          {fellow.name}
        </Typography>
        {fellow.links && fellow.links.length > 0
          ? fellow.links.map((link, index) => (
              <Box
                key={index}
                sx={{
                  mt: 1,
                  display: "flex",
                  alignItems: "flex-start",
                  flexWrap: "nowrap",
                  gap: 1,
                }}
              >
                {link.type === "instagram" ? (
                  <Instagram fontSize="small" />
                ) : null}
                {link.type === "article" ? (
                  <FeedOutlined fontSize="small" />
                ) : null}
                {link.type === "website" ? <Language fontSize="small" /> : null}
                {link.type === "video" ? <MusicVideo fontSize="small" /> : null}
                <Link
                  underline="hover"
                  variant="body2"
                  href={link.href}
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{ fontWeight: "500" }}
                >
                  {link.title}
                </Link>
              </Box>
            ))
          : null}
        {fellow.track ? (
          <Box sx={{ mt: 1.5 }}>
            <iframe
              title={`${fellow.name} - Spotify audio player`}
              allowFullScreen={undefined}
              style={{ border: 0, borderRadius: "12px" }}
              src={fellow.track}
              width="100%"
              height="152"
              allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
              loading="lazy"
            />
          </Box>
        ) : null}
        {fellow.video ? (
          <Box sx={{ mt: 1.5, pb: 1 }}>
            <Box
              sx={{ position: "relative", height: 0, paddingBottom: "56.25%" }}
            >
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  height: "100%",
                  width: "100%",
                }}
              >
                <iframe
                  title={`${fellow.name} - YouTube video player`}
                  allowFullScreen={undefined}
                  width="100%"
                  height="100%"
                  src={fellow.video}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  style={{ borderRadius: "12px" }}
                />
              </Box>
            </Box>
          </Box>
        ) : null}
      </Box>
      <Divider light sx={{ mt: 1 }} />
    </Box>
  );
};

const Fellows: React.FC<Props> = ({ sx }) => {
  return (
    <Box sx={sx}>
      <Box
        sx={{
          bgcolor: "grey.100",
          borderRadius: 1,
          p: 2,
        }}
      >
        <Typography
          variant="h5"
          sx={{
            fontWeight: "900",
            fontSize: { xs: "h6.fontSize", sm: "h5.fontSize" },
            mb: 0.5,
          }}
        >
          Tidigare stipendiater
        </Typography>
        <Box
          sx={{
            height: "100%",
            minHeight: 400,
            maxHeight: "75vh",
            overflowY: "auto",
          }}
        >
          <Timeline
            sx={{
              p: 0,
              [`& .${timelineItemClasses.root}:before`]: {
                flex: 0,
                padding: 0,
              },
            }}
          >
            {fellows.map((entry, index) => (
              <TimelineItem key={entry.year} sx={{ p: 0 }}>
                <TimelineSeparator>
                  <TimelineDot sx={{ boxShadow: "none" }} />
                  {index !== fellows.length - 1 ? (
                    <TimelineConnector sx={{ bgcolor: "divider" }} />
                  ) : null}
                </TimelineSeparator>
                <TimelineContent>
                  <Typography
                    variant="h5"
                    sx={{ fontWeight: "900", lineHeight: 1.15 }}
                  >
                    {entry.year}
                  </Typography>
                  {entry.rewards.length > 0 ? (
                    entry.rewards.map((fellow, i) => (
                      <Card
                        key={`${index}-${entry.year}-${i}`}
                        fellow={fellow}
                      />
                    ))
                  ) : (
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ mt: 1.5 }}
                    >
                      Inga stipendiater.
                    </Typography>
                  )}
                </TimelineContent>
              </TimelineItem>
            ))}
          </Timeline>
        </Box>
      </Box>
    </Box>
  );
};

export default Fellows;
