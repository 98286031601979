import React from "react";
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  SxProps,
  Typography,
} from "@mui/material";
import { TaskAlt } from "@mui/icons-material";

interface Props {
  sx?: SxProps;
}

interface SectionProps {
  title: string;
  description?: React.ReactNode;
  items?: Array<React.ReactNode>;
  sx?: SxProps;
}

const Section: React.FC<SectionProps> = ({ title, items, description, sx }) => {
  return (
    <Box
      sx={{
        borderRadius: 1,
        background: (theme) =>
          `linear-gradient(120deg, ${theme.palette.grey[50]} 0%, ${theme.palette.background.default} 75%)`,
        p: 2,
        ...sx,
      }}
    >
      <Box sx={{ mb: { xs: 1, sm: 2 } }}>
        <Typography
          variant="h5"
          sx={{
            fontWeight: "900",
            fontSize: { xs: "h6.fontSize", sm: "h5.fontSize" },
          }}
        >
          {title}
        </Typography>
      </Box>
      {description ? (
        <Typography
          variant="body2"
          sx={{ maxWidth: 600, mb: { xs: 1, sm: 2 } }}
        >
          {description}
        </Typography>
      ) : null}
      {items ? (
        <List dense sx={{ width: "100%", maxWidth: 600, pb: 0 }}>
          {items.map((item, index) => (
            <ListItem key={`${title}-${index}`}>
              <ListItemIcon>
                <TaskAlt fontSize="small" sx={{ color: "success.light" }} />
              </ListItemIcon>
              <ListItemText
                primary={item}
                sx={{
                  "& .MuiListItemText-primary": {
                    fontSize: "body2.fontSize",
                  },
                }}
              />
            </ListItem>
          ))}
        </List>
      ) : null}
    </Box>
  );
};

const Details: React.FC<Props> = ({ sx }) => {
  return (
    <Box sx={sx}>
      <Section
        title="Vem kan söka?"
        items={[
          <React.Fragment>
            Sökande får vara högst 30 år under ansökningsåret.
          </React.Fragment>,
          <React.Fragment>
            Sökandens egentliga yrkesutbildning ska vara avslutad och viss
            praktisk yrkesutövning ska kunna styrkas.
          </React.Fragment>,
          <React.Fragment>
            Stipendierna är inte avsedda som allmänt studiestöd,
            meritstipendier, projektbidrag, arbetslöshetsersättning eller för
            instrumentinköp.
          </React.Fragment>,
        ]}
        sx={{ mb: { xs: 4, md: 6 } }}
      />
      <Section
        title="Kriterier"
        description={
          <React.Fragment>
            Det finns ingen särskild ansökningsblankett, så den sökande får
            själv disponera upplägget av ansökan, men för att ansökan skall
            bedömas måste följande kriterier uppfyllas.
          </React.Fragment>
        }
        items={[
          <React.Fragment>Personlig presentation.</React.Fragment>,
          <React.Fragment>Nödvändiga intyg och betyg.</React.Fragment>,
          <React.Fragment>
            Tydlig beskrivning av vad stipendiet ska användas till.
          </React.Fragment>,
          <React.Fragment>
            För <strong>sångare och instrumentalister</strong> krävs ett
            ljudprov i form av ljudfil, cd eller liknande.
          </React.Fragment>,
          <React.Fragment>
            För <strong>dansare</strong> krävs någon form av bildproduktion.
          </React.Fragment>,
          <React.Fragment>
            Ansökan skall vara stiftelsen tillhanda senast den{" "}
            <strong>15 maj</strong> varje år.
          </React.Fragment>,
        ]}
        sx={{ mb: { xs: 4, md: 6 } }}
      />
      <Section
        title="Uppföljning"
        description={
          <React.Fragment>
            Senast ett år efter att stipendiet delats ut förbinder sig sökande
            som tilldelats stipendium att lämna en kort redogörelse över vad
            stipendiet använts till.
          </React.Fragment>
        }
      />
    </Box>
  );
};

export default Details;
