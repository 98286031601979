import React from 'react'
import { Box, Paper, SxProps, Tab, Tabs, Typography } from '@mui/material'
import { tabs } from 'src/data/tabs'

interface Props {
    sx?: SxProps
}

const labels: Array<string> = ['Bakgrund', 'Musik', 'Balettskor', 'Stiftelsen']

const Background: React.FC<Props> = ({ sx }) => {
    const [tab, setTab] = React.useState<number>(0)

    return (
        <Box sx={sx}>
            <Paper
                variant="outlined"
                sx={{
                    maxWidth: '100%',
                    bgcolor: 'background.default',
                    overflow: 'hidden'
                }}
            >
                <Tabs
                    allowScrollButtonsMobile
                    variant="scrollable"
                    scrollButtons="auto"
                    orientation="horizontal"
                    value={tab}
                    onChange={(_, value) => setTab(value)}
                    sx={{
                        borderBottom: 1,
                        borderColor: 'divider',
                        bgcolor: 'grey.50'
                    }}
                >
                    {labels.map((label, index) => (
                        <Tab
                            key={`tab-${index}`}
                            value={index}
                            label={label}
                            sx={{
                                fontSize: { xs: 'caption.fontSize', md: 'body2.fontSize' },
                                textTransform: 'capitalize'
                            }}
                        />
                    ))}
                </Tabs>
                <Box sx={{ p: 2 }}>
                    <Typography variant="body1" sx={{ fontSize: 'h6.fontSize', fontWeight: '900', mb: 2 }}>
                        Stiftelsens historia
                    </Typography>
                    {tabs[tab].map((content, index) => (
                        <Typography
                            key={`panel-${tab}-${index}`}
                            variant="body2"
                            sx={{ mt: index === 0 ? 0 : 2 }}
                        >
                            {content}
                        </Typography>
                    ))}
                </Box>
            </Paper>
        </Box>
    )
}

export default Background
