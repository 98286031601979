import type { Fellow } from "src/types";

export const fellows: Array<{ year: number; rewards: Array<Fellow> }> = [
  {
    year: 1996,
    rewards: [{ name: "Jenny Widegren", category: "Dans" }],
  },
  {
    year: 1997,
    rewards: [],
  },
  {
    year: 1998,
    rewards: [
      { name: "David Dalmo", category: "Dans" },
      {
        name: "Daniel Bingert",
        category: "Bas",
        track:
          "https://open.spotify.com/embed/track/4JTfBNsw4AblknqRIP3Q8u?utm_source=generator",
        links: [
          {
            type: "article",
            title: "Daniel Bingert på Kulturhuset Stadsteatern",
            href: "https://tkt.kulturhusetstadsteatern.se/medv/medv.php?id=3652",
          },
        ],
      },
    ],
  },
  {
    year: 1999,
    rewards: [
      { name: "Amedo Nicoletti", category: "Gitarr" },
      { name: "Belinda Riquele", category: "Cello" },
      { name: "Ingela Arrbrant", category: "Dans" },
      { name: "Jenny Boman", category: "Sång" },
    ],
  },
  {
    year: 2000,
    rewards: [
      { name: "Alejandro Lemos", category: "Gitarr" },
      { name: "Anna Rydgren", category: "Dans" },
      {
        name: "Karolina Vucidolac",
        category: "Sång",
        track:
          "https://open.spotify.com/embed/track/0cC9AezkoIZlFffOWvD4B8?utm_source=generator",
        links: [
          {
            type: "article",
            title: "Karolina Vucidolac at Gallery & More",
            href: "https://galleryandmore.eu/artists/karolina-vucidolac",
          },
        ],
      },
    ],
  },
  {
    year: 2001,
    rewards: [
      { name: "Jenny Holmgren", category: "Sång" },
      { name: "Sandra Johansson", category: "Dans" },
    ],
  },
  {
    year: 2002,
    rewards: [
      {
        name: "Lisa Rydberg",
        category: "Fiol",
        track:
          "https://open.spotify.com/embed/track/0j2drkB1hWqEJoWIABARRj?utm_source=generator",
        video: "https://www.youtube-nocookie.com/embed/Ze_qRqWVzOE?controls=0",
        links: [
          {
            type: "website",
            title: "lisarydberg.com",
            href: "https://www.lisarydberg.com/",
          },
        ],
      },
      { name: "Tobias Ågren", category: "Sång" },
      { name: "Åsa Malmström", category: "Dans" },
    ],
  },
  {
    year: 2003,
    rewards: [{ name: "Javier Muños", category: "Sång" }],
  },
  {
    year: 2004,
    rewards: [{ name: "Hans Olding", category: "Gitarr" }],
  },
  {
    year: 2005,
    rewards: [
      { name: "Moa Danielsson", category: "Tablas" },
      { name: "Susanna Limell", category: "Dans" },
    ],
  },
  {
    year: 2006,
    rewards: [
      { name: "Liv Örnefors", category: "Dans" },
      { name: "Nils Jansson", category: "Trumpet" },
    ],
  },
  {
    year: 2007,
    rewards: [
      { name: "Adam Forkelid", category: "Piano" },
      { name: "Tobias Sondén", category: "Bas" },
    ],
  },
  {
    year: 2008,
    rewards: [{ name: "Kristoffer Persson", category: "Dans" }],
  },
  {
    year: 2009,
    rewards: [
      {
        name: "Carl Bagge",
        category: "Piano",
        track:
          "https://open.spotify.com/embed/track/62nSghHUT2LHaMukgWuyCx?utm_source=generator",
        video: "https://www.youtube-nocookie.com/embed/dIfxwXAqsaw?controls=0",
        links: [
          {
            type: "article",
            title: "Carl Bagge på Scalateatern",
            href: "https://www.scalateatern.se/senaste-nytt/om-carl-bagge/",
          },
        ],
      },
      { name: "Petra Ahlmark", category: "Gitarr" },
    ],
  },
  {
    year: 2010,
    rewards: [
      { name: "Lovisa Lindkvist", category: "Sång" },
      { name: "Matilda Bådagård", category: "Sång" },
      { name: "Susanna Risberg", category: "Gitarr" },
    ],
  },
  {
    year: 2011,
    rewards: [
      { name: "Elin Larsson", category: "Saxofon" },
      { name: "Kristian Persson", category: "Trombon" },
    ],
  },
  {
    year: 2012,
    rewards: [
      { name: "Isabell Takkunen", category: "Dans" },
      { name: "Lena Svanberg", category: "sång" },
    ],
  },
  {
    year: 2013,
    rewards: [
      { name: "Jana Schulz", category: "Dans" },
      { name: "Oskar Stenmark", category: "Trumpet" },
    ],
  },
  {
    year: 2014,
    rewards: [{ name: "Jonas Strandgård", category: "Gitarr" }],
  },
  {
    year: 2015,
    rewards: [
      { name: "Ellie Jeppesen Stratigentas", category: "Dans" },
      { name: "Fia Forslund", category: "Slagverk" },
    ],
  },
  {
    year: 2016,
    rewards: [
      {
        name: "Graciela Chin A Loi",
        category: "Sång",
        track:
          "https://open.spotify.com/embed/track/2W4XFfUl2FdOiUNPyDObnS?utm_source=generator",
        links: [
          {
            type: "article",
            title: "Graciela Chin A Loi på Fasching",
            href: "https://www.fasching.se/graciela-chin-a-loi/",
          },
        ],
      },
      {
        name: "Sirintip Phasuk",
        category: "Sång",
        track:
          "https://open.spotify.com/embed/track/6lAAg667cKkDzU5YNZ0WPH?utm_source=generator",
        video: "https://www.youtube-nocookie.com/embed/d3wXQ65pTeI?controls=0",
        links: [
          {
            type: "website",
            title: "sirintipofficial.com",
            href: "https://www.sirintipofficial.com/",
          },
        ],
      },
    ],
  },
  {
    year: 2017,
    rewards: [
      {
        name: "Tania Salazar Pålsson",
        category: "Sång",
        track:
          "https://open.spotify.com/embed/track/1PG4OgJxe6Fraru9g50qN0?utm_source=generator",
      },
    ],
  },
  {
    year: 2018,
    rewards: [],
  },
  {
    year: 2019,
    rewards: [
      {
        name: "Jonathan Bondesson",
        category: "Gitarr",
        track:
          "https://open.spotify.com/embed/track/3YrmElcsLMXq2nQwv2vQin?utm_source=generator",
        video: "https://www.youtube-nocookie.com/embed/j7QXTKpofmo?controls=0",
        links: [
          {
            type: "website",
            title: "jonathanbondesson.com",
            href: "https://www.jonathanbondesson.com/",
          },
        ],
      },
    ],
  },
  {
    year: 2020,
    rewards: [],
  },
  {
    year: 2021,
    rewards: [
      {
        name: "Frida Linnell",
        category: "Sång",
        track:
          "https://open.spotify.com/embed/track/2NwtBu0p14IbTeUPSLNeAR?utm_source=generator",
        video: "https://www.youtube-nocookie.com/embed/GJht0KTzaZE?controls=0",
        links: [
          {
            type: "website",
            title: "fridalinell.se",
            href: "https://fridalinnell.se/",
          },
        ],
      },
      {
        name: "Klara Ekman von Huth",
        category: "Sång",
        video: "https://www.youtube-nocookie.com/embed/8IblkB5AvfQ?controls=0",
      },
    ],
  },
  {
    year: 2022,
    rewards: [
      {
        name: "Klara Källström",
        category: "Cello",
        video: "https://www.youtube-nocookie.com/embed/OxNuekomrFY?controls=0",
        links: [
          {
            type: "article",
            title: "Tobisstipendiet till Klara Källström",
            href: "http://www.musikindustrin.se/2022/09/03/tobisstipendiet-till-klara-kallstrom/",
          },
        ],
      },
    ],
  },
  {
    year: 2023,
    rewards: [
      {
        name: "Ivan Persson",
        category: "Sång & Musikal",
        video: "https://www.youtube-nocookie.com/embed/iyUntCnfEXw?controls=0",
        links: [
          {
            type: "instagram",
            title: "ivanpersson",
            href: "https://www.instagram.com/ivanpersson/",
          },
        ],
      },
    ],
  },
  {
    year: 2024,
    rewards: [
      {
        name: "Josefine Hebbe",
        category: "Sång & Musikal",
        video: "https://www.youtube-nocookie.com/embed/tBNuzpmChkc?controls=0",
        track:
          "https://open.spotify.com/embed/track/5tNZ1Wj6jXBuTKLeDX5w5S?utm_source=generator&theme=0",
        links: [
          {
            type: "instagram",
            title: "josefinehebbe",
            href: "https://www.instagram.com/josefinehebbe/",
          },
          {
            type: "website",
            title: "The Hebbe Sisters",
            href: "https://thehebbesisters.se/",
          },
          {
            type: "article",
            title: "Josefine Hebbe får stipendium för unga musiker och dansare",
            href: "https://www.saffletidningen.se/2024/10/07/josefine-hebbe-far-stipendium-for-unga-musiker-och-dansare-151b0/",
          },
        ],
      },
    ],
  },
].reverse();
