import React from "react";
import { Box, ButtonBase, SxProps, Typography } from "@mui/material";
import { Mail } from "@mui/icons-material";

interface Props {
  sx?: SxProps;
}

const Address: React.FC<Props> = ({ sx }) => {
  return (
    <Box
      sx={{
        borderRadius: 1,
        bgcolor: "primary.dark",
        p: { xs: 2, sm: 4 },
        ...sx,
      }}
    >
      <Typography
        variant="h5"
        color="background.default"
        sx={{
          fontWeight: "900",
          fontSize: { xs: "h6.fontSize", sm: "h5.fontSize" },
        }}
      >
        Ansökan
      </Typography>
      <Typography
        variant="body1"
        color="background.default"
        sx={{
          fontWeight: "700",
          fontSize: { xs: "body2.fontSize", sm: "body1.fontSize" },
          mb: 3,
        }}
      >
        Senast den 15 maj varje år
      </Typography>
      <Typography
        variant="body1"
        color="primary.light"
        sx={{
          fontWeight: "500",
          fontSize: { xs: "body2.fontSize", sm: "body1.fontSize" },
          lineHeight: 1,
          mb: 1,
        }}
      >
        Skickas via mail
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          flexWrap: "wrap",
          justifyContent: "space-between",
          gap: 2,
        }}
      >
        <Box>
          <ButtonBase
            href="mailto:info@margottobis.se"
            sx={{
              color: "background.default",
              fontWeight: "700",
              fontSize: { xs: "body2.fontSize", sm: "body1.fontSize" },
              mb: 3,
            }}
          >
            info@margottobis.se
          </ButtonBase>
          <Typography
            variant="body1"
            color="primary.light"
            sx={{
              fontWeight: "500",
              fontSize: { xs: "body2.fontSize", sm: "body1.fontSize" },
              lineHeight: 1,
              mb: 1,
            }}
          >
            Eller per post
          </Typography>
          {[
            "Margot & Tobis Stiftelse",
            "c/o Tonnie Norling",
            "Drottningholmsvägen 372",
            "168 73 Bromma",
          ].map((address, index) => (
            <Typography
              key={index}
              variant="body1"
              color="background.default"
              sx={{
                fontWeight: "700",
                fontSize: { xs: "body2.fontSize", sm: "body1.fontSize" },
              }}
            >
              {address}
            </Typography>
          ))}
        </Box>
        <Box
          sx={{
            width: { xs: 100, sm: 140 },
            height: { xs: 100, sm: 140 },
            borderRadius: "50%",
            bgcolor: "primary.main",
            color: "primary.light",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: { xs: "h2.fontSize", sm: "h1.fontSize" },
          }}
        >
          <Mail fontSize="inherit" color="inherit" />
        </Box>
      </Box>
    </Box>
  );
};

export default Address;
