import React from 'react'

export const tabs: Array<Array<React.ReactNode>> = [
    [
        <React.Fragment>
            Torvald ”Tobis” Tobiasson-Tollgren föddes 16 maj 1910 i Sannäs i Bohuslän.
        </React.Fragment>,
        <React.Fragment>
            Sannäs befolkades främst av fiskare och stenhuggare, och kring seklets första decennier
            skeppades mängder av gat­sten ut från hamnen till Buenos Aires. Argentinska lastbåtar
            ankrade runt hamnen för att lasta enorma stenmassor och sjömännen förde med sig instrument
            och spelade upp till lördagsdans när de hade landpermission.
        </React.Fragment>,
        <React.Fragment>
            Tobis visade i tidig ålder att han hade en musikalisk begåvning. Han inspirerades av den
            exotiska tangomusiken och beslöt senare att yrkesmässigt ägna sig åt den typen av musik.
        </React.Fragment>,
        <React.Fragment>
            I början av 30-talet for Tobis och ett par vänner till Barcelona i Spanien för gitarrstudier.
            På Barcelonas många nöjeslokaler spelade även orkestrar från Havanna, Buenos Aires och Montevideo.
            Unge Tobis fick lektioner av musiker från dessa band och erbjöds också tillfällen att "sitta in"
            i orkestrarna.
        </React.Fragment>
    ],
    [
        <React.Fragment>
            När Francoarméns erövringar drastiskt förändrade livet i Spanien, reste Tobis och hans vänner hem
            till Sverige igen. Tillbaka i Stockholm ströspelade Tobis, inte alltid med ekonomisk framgång, då få
            medmusiker kunde spela med i de för dem nya rytmerna. Tango i svensk marschliknande tappning var en
            populär dans och rumba började sakta vinna mark och Tobis kom att betyda mycket som lärare för de
            svenska dansmusikerna. I Spanien hade han även lärt sig dansa såväl spanska som latinamerikanska danser.
            Han kunde därför dryga ut inkomsterna genom att framträda också som exotisk dansör och det slog bättre
            an även på Stockholmspubliken.
        </React.Fragment>,
        <React.Fragment>
            1938 bildade Tobis sin första rumbaorkester – en trio med fiol, dragspel och gitarr. Alla tre musikerna var
            även sångare och kunde hantera ett par maracas, claves eller en congatrumma. För skivinspelningar utökades
            trion med flöjt, bas och trummor.
        </React.Fragment>,
        <React.Fragment>
            Under 40-talet uppnådde <em>Tobis y sus gauchos</em> sina största publika succéer någonsin. Krigsårens stängda
            gränser skapade behov av verklighetsflykt hos svenskarna, och exotisk musik som rumba, conga, samba och tango
            blev en tankeflykt till varmare länder.
        </React.Fragment>,
    ],
    [
        <React.Fragment>
            1956 upplöste Tobis sin orkester, han var sedan flera år gift med dansösen Margot Appelgren och de båda startade
            nu en affär för balettillbehör. De importerade bl a balettskor från London, enda sätet för Europas hela behov av
            balettskor. Paret ansåg att de snabbt utslitna balettskorna var på tok för dyra och startade en egen tillverkning
            av balettskor i Tobis barndomstrakt, Sannäs. Samhället hade vid den tiden börjat avfolkas och för att rädda samhällets
            existens importerade Tobis de specialmaskiner som krävdes för att garantera balettskor av kvalitetstillverkning.
            Han och Margot experimenterade med sin tillverkning och omskolade därefter samhällets fiskare och stenhuggare med
            fruar och barn till verksamhet inom balettskomakaryrket.
        </React.Fragment>,
        <React.Fragment>
            Med en gedigen ekonomisk grund började Tobis med husaffärer när billig import började ta över, och även med detta
            lyckades Tobis bra. Så bra att han under 80-talet kunde avveckla affären och inrätta sig för ett bekvämt liv
            tillsammans med Margot. Båda två hade under hela karriären haft målning och teckning som hobby, och nu kunde de
            utveckla sina intressen på det området, Tobis tog upp skulptering.
        </React.Fragment>
    ],
    [
        <React.Fragment>
            Äktenskapet var och förblev barnlöst varför Tobis började planera för hur hans och Margots stora förmögenhet, på flera
            miljoner skulle kunna gynna unga efterföljare till hans och hustruns musikaliska och dansanta yrkesverksamhet.
            Margot drabbades under 80-talet av en demenssjukdom men hann ändå uppleva att en stiftelse grundats i hennes och Tobis
            namn innan hon försvann in i den svåra sjukdomens dimmor. Tobis själv avled i skelettcancer den 24 mars 1996.
        </React.Fragment>,
        <React.Fragment>
            Det första stipendiet från deras stiftelse delades ut i Sannäs på Tobis födelsedag, den 16 maj, samma år. I samband med
            utdelandet invigdes också en staty, <em>Nymfen</em>, som Tobis skapat och skänkt till sin hembygd. Den står i dag vackert placerad
            vid inloppet till hamnen i Sannäs.
        </React.Fragment>
    ]
]
