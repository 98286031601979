import React from 'react'
import Logo from './Logo'
import { Box, Typography } from '@mui/material'

const Footer: React.FC = () => {
    return (
        <Box component="footer" sx={{ px: 2 }}>
            <Box
                sx={{
                    bgcolor: 'grey.200',
                    color: 'grey.500',
                    display: 'flex',
                    borderRadius: 1,
                    borderBottomLeftRadius: 0,
                    borderBottomRightRadius: 0,
                    flexDirection: 'column',
                    alignItems: 'center',
                    py: 5
                }}
            >
                <Logo
                    sx={{
                        color: 'primary.main',
                        height: 70,
                        width: 70,
                        mb: 3
                    }}
                />
                <Typography variant="body2" sx={{ fontWeight: '500', fontSize: 'caption.fontSize' }}>
                    Copyright © {new Date().getFullYear()} Margot & Tobis Stiftelse.
                </Typography>
                <Typography variant="body2" sx={{ fontWeight: '500', fontSize: 'caption.fontSize' }}>
                    All rights reserved.
                </Typography>
            </Box>
        </Box>
    )
}

export default Footer
