import React from "react";
import { Box, Container, Typography } from "@mui/material";

const Intro: React.FC = () => {
  return (
    <Container
      sx={{
        pt: { xs: 4, sm: 8, md: 12, lg: 14 },
        pb: { xs: 2, md: 8 },
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          flexDirection: { xs: "column-reverse", sm: "row" },
          gap: 4,
        }}
      >
        <Box>
          <Box sx={{ mb: 2 }}>
            <Typography
              variant="h1"
              sx={{
                fontWeight: "900",
                fontSize: {
                  xs: "1.5rem",
                  sm: "2rem",
                  md: "2.5rem",
                  lg: "2.85rem",
                },
              }}
            >
              Margot & Tobis Stiftelse
            </Typography>
            <Typography
              variant="h2"
              sx={{
                fontWeight: "700",
                fontSize: {
                  xs: "0.85rem",
                  sm: "1.125rem",
                  md: "1.4rem",
                  lg: "1.6rem",
                },
              }}
            >
              Stipendiet för musiker, dansare och sångare
            </Typography>
          </Box>
          <Box sx={{ maxWidth: { xs: 400, sm: 460, md: 580, lg: 620 } }}>
            <Typography variant="body2" sx={{ fontWeight: "500", mb: 2 }}>
              Margot & Tobis Stiftelse vill skapa möjlighet för talangfulla
              musiker, dansare och sångare att utvecklas som artister samt ge
              dem möjlighet till vidareutbildning.
            </Typography>
            <Typography variant="body2" sx={{ fontWeight: "500", mb: 2 }}>
              Stiftelsen prioriterar de områden/genrer inom konst och repertoar
              som stod Margot och Tobis närmast: latinamerikansk musiktradition,
              musikal och folkmusik, även jazz, men då av det mer underhållande,
              showinriktade slaget.
            </Typography>
            <Typography variant="body2" sx={{ fontWeight: "500", mb: 2 }}>
              Stiftelsen delar varje år ut 1-2 stipendier på vardera 50 000
              kronor.
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexGrow: 1,
          }}
        >
          <Box
            sx={{
              height: { xs: 150, sm: 175, md: 250 },
              width: { xs: 150, sm: 175, md: 250 },
              borderRadius: "50%",
              borderWidth: 4,
              borderStyle: "solid",
              borderColor: "grey.100",
              background: `url("${process.env.PUBLIC_URL}/media/tobis.jpg") 20% center / cover no-repeat`,
            }}
          />
        </Box>
      </Box>
    </Container>
  );
};

export default Intro;
